import React from 'react'
import Dashboard from "./views/Dashboard";


function App() {
  return (
    <Dashboard />
  );
}

export default App;
